import { createUseStyles } from '../../helpers/createStyles'
import { theme } from '../../styles/theme'

const BackgroundColorSwitcher = ({ data, children }) => {
  const styles = useStyles()
  const { color } = data
  const { background, foreground, border } = theme.colors.pageTheme[color.title]
  return (
    <div
      className={styles.container}
      style={{ '--background': background, '--foreground': foreground, '--border': border }}
    >
      {children}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    paddingTop: theme.section.marginBottom.sm,
    backgroundColor: theme.colors.background,
    color: theme.colors.text,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.section.marginBottom.md
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.section.marginBottom.xl
    },
    '&::after': {
      content: '""',
      clear: 'both',
      display: 'table'
    }
  }
})

export default BackgroundColorSwitcher
